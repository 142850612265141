<template>
    <div>
        <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
            <div class="row justify-content-around">
                <div class="card-body mb-3 pb-0"><h2 class="fw-400 font-lg d-block"><b>Coupon</b></h2></div>
                <div class="col-lg-2 col-xl-2 col-md-2 col-sm-2">
                    <router-link :to="'/admin/courses/'+paramsId+'/coupon/create'" class="w-100 d-block btn bg-current text-white font-xssss fw-700 ls-3 style1-input p-3 border-0 text-uppercase ">
                        <i class="fas fa-plus" style="margin-right: 5px;"></i>
                    </router-link>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center py-3">
                    <div class="my-3" v-show="loadData">
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>

                    </div>
                    <img :src="'/images/empty.png'" v-show="dataNotExist" alt="" width="300">
                    <h1 class="text-muted" v-show="dataNotExist">Data don't exist</h1>
                </div>
            </div>
            <div class="card-body w-100" v-show="dataExist">
                <div class="row">
                    <div  class="col-xl-12 col-lg-12">
                        <input type="text" class="form-control" placeholder="Search..." v-model="tableFilter">
                    </div>
                    <div class="col-xl-12 col-lg-12 text-center py-3">
                        <div class="table-responsive mw-100 pr-2 ml-0">
                            <datatable :class="'table table-hover table-bordered'" :columns="columns" :filter="tableFilter" :data="coupons" :page="1" :perPage="10" :waitForPager="true">
                                <template name="default" slot-scope="{ row, index }">
                                    <tr>
                                        <td>{{ index+1 }}</td>
                                        <td>{{ row.code }}</td>
                                        <td>{{ row.quota }}</td>
                                        <td>
                                            <button class="btn btn-sm" :class="{'btn-success': row.cycle_1, 'btn-danger': !row.cycle_1}">1</button>
                                            <button class="btn btn-sm mx-2" :class="{'btn-success': row.cycle_2, 'btn-danger': !row.cycle_2}">2</button>
                                            <button class="btn btn-sm" :class="{'btn-success': row.cycle_3, 'btn-danger': !row.cycle_3}">3</button>
                                        </td>
                                        <td>
                                            <button class="btn btn-sm" :class="{'btn-success': row.cycle_1_special, 'btn-danger': !row.cycle_1_special}">1</button>
                                            <button class="btn btn-sm mx-2" :class="{'btn-success': row.cycle_2_special, 'btn-danger': !row.cycle_2_special}">2</button>
                                            <button class="btn btn-sm" :class="{'btn-success': row.cycle_3_special, 'btn-danger': !row.cycle_3_special}">3</button>
                                        </td>
                                        <td>
                                            <router-link :to="'/admin/courses/'+paramsId+'/coupon/'+row.id+'/edit'"><i class="ti-pencil font-xs text-grey-500 mr-4"></i></router-link>
                                            <a href="javascript:void(0)" @click="deleteAlert(row.id)"><i class="ti-trash font-xs text-grey-500"></i></a>
                                        </td>
                                    </tr>
                                </template>
                                <template name="no-result">
                                    Nothing to see here
                                </template>
                            </datatable>
                        </div>
                        <datatable-pager v-model="page" type="abbreviated"></datatable-pager>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    data(){
        return{
            paramsId: this.$route.params.idCourse,
            coupons: [],
            dataExist: false,
            dataNotExist: false,
            loadData: true,
            tableFilter: '',
            page: 1,
            columns: [
                {label: 'No', field: 'id',headerClass: 'border-0 bg-current text-white p-3', class: 'product-thumbnail text-center', filterable: false, sortable: false},
                {label: 'Code', field: 'code', headerClass: 'border-0 bg-current text-white p-3', class: 'product-thumbnail text-center wide-column'},
                {label: 'Amount', field: 'quota', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center'},
                {label: 'Cycle', field: '', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false},
                {label: 'Special', field: '', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false},
                {label: 'Option', headerClass: 'border-0 bg-current text-white p-3', class: 'product-remove text-center', filterable: false, sortable: false},
            ],
            rows: [
                this.coupons
            ]
        }
    },
    created(){
        this.getCoupon()
    },
    methods:{
        async getCoupon() {
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/coupon?slug=${this.paramsId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.loadData = false
                if (res.data.length == 0) {
                    this.dataNotExist = true
                    this.dataExist = false
                } else {
                    this.dataExist = true
                    this.dataNotExist = false
                    this.coupons = res.data
                }
            }).catch((err) => console.error(err))
        },
        deleteAlert(id) {
            this.$swal({
                title: 'Are you sure?',
                text: 'You can\'t revert your action',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes Delete it!',
                cancelButtonText: 'No, Keep it!',
                showCloseButton: true,
                showLoaderOnConfirm: true
                }).then((result) => {
                if(result.value) {
                    this.deleteCoupon(id)
                } else {
                    this.$swal({
                        toast: true,
                        title: 'Cancelled',
                        text: 'Your data is still intact',
                        icon: 'info',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        },
        async deleteCoupon(id){
            var data = {
                id: id,
                _method: 'delete'
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/coupon` ,data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then(res => {
                this.$swal({
                    toast: true,
                    title: 'Success Delete This Coupon !',
                    text: res.data.message,
                    icon: 'success',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
                this.getCoupon()
            }).catch(err => {
                if (err.response.status == 422){
                    this.validationErrors = Object.values(err.response.data.errors);
                    this.$swal({
                        toast: true,
                        title: 'Failed Delete This Coupon !',
                        text: this.validationErrors[0][0],
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }
            })
        }
    }
}

</script>